import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Table, DatePicker, Form, Input, Button, Select, message, Spin } from 'antd';
import 'antd/dist/antd.css';
import './index.css';
import { createStructuredSelector } from 'reselect';
import { StyledReport } from '../../style';
import { StyledForm, StyledModal } from '../../../AMM/CSS/style';
import themeSettings from '../../../AMM/Utils/themeSettings.json';
import { getCurrentTimezone, getUserProfile } from '../../../../selectors/layout';
import { getTranslationCache } from '../../../../selectors/language';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import axios from 'axios';
import { CUSTOM_REPORT, REPORTS } from '../../../../commons/api';
import localStorage from '../../../../utils/localStorage';

const App = (props) => {
  const [formData, setFormData] = useState({});
  const [currentDate, setCurrentDate] = useState(moment().subtract(1, 'day'));
  const [data, setData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [submitModal, setSubmitModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      try {
        let userList = await getUsersList();
        let date = moment(currentDate);
        let table_response = await _getReportList(date);
        let form_response = await getReportData(date);
        if (table_response && table_response.status && table_response.status === 200) {
          setLoading(false);
        } else {
          setLoading(true);
        }

        // let response = await getReportData(date);

        // if (response && response.status === 200) {
        //   if (
        //     response &&
        //     response.data &&
        //     response.data.formData &&
        //     response.data.formData.Status &&
        //     response.data.formData.Status === 'Open'
        //   ) {
        //     let ReportData = await _getReportList(date);
        //     if (ReportData) {
        //       let ReportTableData = {
        //         tableData: ReportData && ReportData.data,
        //         formData: response.data.formData
        //       };
        setCurrentDate(date);
        setData(table_response && table_response.data);
        setFormData(form_response && form_response.data && form_response.data.formData);
        //       setLoading(false);
        //     }
        //   } else {
        //     let date =
        //       response &&
        //       response.data &&
        //       response.data.formData &&
        //       response.data.formData.Date &&
        //       moment(response.data.formData.Date);
        //     setData(response.data.tableData);
        //     setCurrentDate(date);
        //     setLoading(false);
        //     setFormData(response.data.formData);
        //   }
        // } else {
        //   setCurrentDate(date);
        // }
        setUserList(userList);
      } catch (e) {
        return {};
      }
    })();
  }, []);
  const translation = (keyword) => {
    let { translationCache } = props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };
  const getReportData = async (currentDate) => {
    const siteId = localStorage.get('currentSite');
    const token = localStorage.get('accessToken');
    let Authorization = 'Bearer ' + token;
    const getReportData = {
      method: 'POST',
      url: `${CUSTOM_REPORT.Get}?SiteId=${siteId}`,
      headers: {
        Authorization: Authorization
      },
      data: {
        Date: moment(currentDate).format('YYYY-MM-DD')
      }
    };
    return axios(getReportData)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        if (error.response.status === 400) {
          message.info(
            translation(
              error && error.response && error.response.data && error.response.data.message
            )
          );
        }
      });
  };

  const columns = [
    {
      title: translation('Time'),
      dataIndex: 'Time',
      key: 'Time',
      render: (text) => {
        if (text === 'Total' || text === 'Average' || text === 'Maximum' || text === 'Minimum') {
          return <span style={{ fontWeight: 'bold' }}>{text}</span>;
        } else {
          return <span>{moment.utc(text).tz(props.currentTimeZone).format('HH:mm')}</span>;
        }
      }
    },
    {
      title: translation('Power Generation Time (h)'),
      dataIndex: 'Power Generation Time (h)',
      key: 'Power Generation Time (h)'
    },
    {
      title: translation('Generator Power (kW)'),
      dataIndex: 'Generator Power (kW)',
      key: 'Generator Power (kW)'
    },
    {
      title: translation('Generator Electric Power Accumulation (kWh)'),
      dataIndex: 'Generator Electric Power Accumulation (kWh)',
      key: 'Generator Electric Power Accumulation (kWh)'
    },
    {
      title: translation('Transmitting Electricity Electric Power Accumulation (kWh)'),
      dataIndex: 'Transmitting Electricity Electric Power Accumulation (kWh)',
      key: 'Transmitting Electricity Electric Power Accumulation (kWh)'
    }
  ];

  const getUsersList = async () => {
    const token = localStorage.get('accessToken');
    var Authorization = 'Bearer ' + token;
    const siteId = localStorage.get('currentSite');
    let data = {
      url: `${CUSTOM_REPORT.RecipientsList}?SiteId=${siteId}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: Authorization
      }
    };
    return axios(data)
      .then((response) => {
        return response.data;
      })

      .catch((error) => {
        message.error(
          translation(error && error.response && error.response.data && error.response.data.message)
        );
      });
  };
  const handleChange = (name, value) => {
    setFormData({ ...(formData || {}), [name]: value });
  };

  const handleSubmit = async (status) => {
    if (status === 'Submitted') {
      setSubmitModal(true);
    }

    let Timezone = momentTZ.tz.guess();
    if (
      formData &&
      formData.Recipients &&
      Array.isArray(formData.Recipients) &&
      formData.Recipients.length > 0
    ) {
      let FormValues = {}
      if (status && status === 'Submitted') {
        FormValues = {
          ...formData,
          Date: moment(currentDate).format('YYYY-MM-DD'),
          Timezone,
          DateType: 'selectedDate',
          Id: formData && formData.Id ? formData.Id : null,
          Status: status,
          Language: localStorage.get('currentLanguage'),
          ReportedByEmail:props && props.userProfile && props.userProfile.user && props.userProfile.user.Email,
          CommentedBy: props && props.userProfile && props.userProfile.user && props.userProfile.user.FirstName
        }
      } else {
        FormValues = {
          ...formData,
          Date: moment(currentDate).format('YYYY-MM-DD'),
          Timezone,
          DateType: 'selectedDate',
          Id: formData && formData.Id ? formData.Id : null,
          Status: status,
          Language: localStorage.get('currentLanguage'),
          ReportedByEmail:props && props.userProfile && props.userProfile.user && props.userProfile.user.Email,
          CommentedBy: props && props.userProfile && props.userProfile.user && props.userProfile.user.FirstName
        }
      }
      
      let payload = {
        tableData: data,
        formData: FormValues
      };

      let saveForm = await saveReportComments(payload);
      if (saveForm && saveForm.message) {
        let table_response = await _getReportList(currentDate);
        let form_response = await getReportData(currentDate)

        if (form_response && form_response.data && form_response.data.formData && form_response.data.formData.Date) {
          let date =
            form_response &&
            form_response.data &&
            form_response.data.formData &&
            form_response.data.formData.Date &&
            moment(form_response.data.formData.Date);

          setCurrentDate(date);
        }
        if (table_response && table_response.status && table_response.status === 200) {
          setLoading(false);
        } else {
          setLoading(true);
        }
        setSubmitModal(false);
        setData(table_response && table_response.data);
        setFormData(form_response && form_response.data && form_response.data.formData);
        message.success(translation(saveForm && saveForm.message));
      }
    } else {
      message.error(translation('Please add Atleast one Recipient'));
    }
  };

  const saveReportComments = async (data) => {
    const siteId = localStorage.get('currentSite');
    const accessToken = localStorage.get('accessToken');
    const Object = {
      method: 'POST',
      url: `${CUSTOM_REPORT.Upsert}?SiteId=${siteId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      data
    };
    return axios(Object)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        message.error(
          translation(err && err.response && err.response.data && err.response.data.message)
        );
      });
  };

  const disabledDate = (current) => {
    return current > moment().subtract(1, 'day').endOf('day');
  };

  // const commentsArray = formData && formData.PreviousComments && formData.PreviousComments.map((previous) => ({
  //   author: previous.username,
  //   avatar:<Avatar icon={<UserOutlined />} />,
  //   content: (
  //     <p>{previous.comment}</p>
  //   ),
  //   datetime: (
  //     <span>{formData.Date}</span>
  //   ),
  // }));
  const _getReportList = (date) => {
    let Timezone = momentTZ.tz.guess();

    let start = moment(date).format('YYYY-MM-DD 00:00:00');
    let end = moment(date).format('YYYY-MM-DD 23:59:59');
    const siteId = localStorage.get('currentSite');
    let accessToken = localStorage.get('accessToken');
    const healthHeaders = {
      method: 'POST',
      url: `${REPORTS.GetReport}?SiteId=${siteId}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      data: {
        ReportType: 'Report',
        ReportName: 'SGET Toki Daily Power Report',
        TabName: 'SGET Toki Daily Power Report',
        Type: 'main',
        TagsList: [
          'Time',
          'PowerGenerationTime',
          'Generat_getReportListorPower',
          'GEPA',
          'Electrical_Power'
        ],
        startDate: moment(start).utc().format('YYYY-MM-DD HH:mm:ss'),
        endDate: moment(end).utc().format('YYYY-MM-DD HH:mm:ss'),
        Month: moment(date).format('MMMM'),
        Year: moment(date).format('YYYY'),
        SiteId: siteId,
        Timezone,
        DateType: 'selectedDate',
        Date: moment(date).format(`YYYY-MM-DD  HH:mm:ss`)
      }
    };
    return axios(healthHeaders)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        if (
          (err && err.response && err.response.status && err.response.status === 400) ||
          (err && err.response && err.response.status && err.response.status === 500)
        ) {
          message.info(
            translation(err && err.response && err.response.data && err.response.data.message)
          );
        }
      });
  };
  const onSubmitReport = () => {
    if (
      formData &&
      formData.Recipients &&
      Array.isArray(formData.Recipients) &&
      formData.Recipients.length > 0
    ) {
      setSubmitModal(false);
      handleSubmit('Submitted');
    } else {
      message.error(translation('Please add Atleast one Recipient'));
    }
    // setFormData({ ...(formData || {}), Id: 1 });
  };
  const OnDateChange = async (currentDate) => {
    let selectedDate = moment(currentDate);
    setLoading(true);
    setCurrentDate(selectedDate);
    let table_response = await _getReportList(selectedDate);
    let form_response = await getReportData(selectedDate)

    // let response = await getReportData(selectedDate);
    // if (response && response.status === 200) {
    //   if (
    //     response &&
    //     response.data &&
    //     response.data.formData &&
    //     response.data.formData.Status &&
    //     response.data.formData.Status === 'Open'
    //   ) {

    //     let ReportData = await _getReportList(currentDate);

    //     let ReportTableData = {
    //       tableData: ReportData && ReportData.data,
    //       formData: response.data.formData
    //     };

    // setCurrentDate(selectedDate);
    if (table_response && table_response.status && table_response.status === 200) {
      setLoading(false);
    } else {
      setLoading(true);
    };

    setData(table_response && table_response.data);
    setFormData(form_response && form_response.data && form_response.data.formData);
    // } else {
    //   // let date =
    //   //   response &&
    //   //   response.data &&
    //   //   response.data.formData &&
    //   //   response.data.formData.Date &&
    //   //   moment(response.data.formData.Date);
    //   setData(response && response.data && response.data.tableData);
    //   // setCurrentDate(date);
    //   setLoading(false);
    //   setFormData(response && response.data && response.data.formData);
    // }
    // }
    // else {
    //   setCurrentDate(selectedDate);
    // }
    // setCurrentDate(selectedDate);
  };

  return (
    <StyledReport style={{ minHeight: window.innerHeight - 69, display: 'flex' }}>
      <div style={{ flex: 2, marginRight: '20px' }}>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <DatePicker
            defaultValue={currentDate}
            value={currentDate}
            clearIcon={null}
            onChange={
              (date) => OnDateChange(date)
              // setCurrentDate(date);
            }
            disabledDate={disabledDate}
            style={{ marginBottom: '20px' }}
          />
        </div>
        <Spin spinning={loading} size="large">
          <Table
            bordered
            // loading={loading}
            className="downtimeTable"
            scroll={{ y: 700 }}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
        </Spin>
      </div>
      <div style={{ flex: 1, marginTop: '45px', padding: '20px' }}>
        <StyledForm
          name="Equipment Master"
          initialValues={formData || {}}
          theme={themeSettings}
          // onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            label={translation('Recipients')}
            rules={[
              {
                required: true,
                message: translation('Please select atleast one Recipient')
              }
            ]}
          >
            <Select
              mode="multiple"
              showArrow
              disabled={
                formData && formData.Status && formData.Status === 'Submitted' ? true : false
              }
              name="Recipients"
              value={formData.Recipients}
              onChange={(value) => handleChange('Recipients', value)}
              placeholder={translation('Please Select Recipients')}
            >
              {userList &&
                Array.isArray(userList) &&
                userList.map((user, index) => {
                  return (
                    <Select.Option key={index} value={user.Email}>
                      {user.Email}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>

          <Form.Item label={translation('Comment')}>
            <Input.TextArea
              name="Comments"
              style={{ overflowY: 'auto' }}
              value={formData.Comments}
              disabled={
                formData && formData.Status && formData.Status === 'Submitted' ? true : false
              }
              rows={4}
              placeholder={translation('Please Enter Comments')}
              onChange={(value) => handleChange('Comments', value.target.value)}
            />
          </Form.Item>
          {formData && formData.CommentedBy ? (
            <Form.Item label={translation('Reported By')}>
              <Input
                name="CommentedBy"
                disabled={true}
                value={formData.CommentedBy}
                placeholder={translation('Autopopulate after Save')}
                onChange={(value) => handleChange('ReportedBy', value.target.value)}
              />
            </Form.Item>
          ) : null}
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              type="primary"
              disabled={
                formData && formData.Status && formData.Status === 'Submitted' ? true : false
              }
              onClick={() => handleSubmit('Saved')}
            >
              {translation('Save')}
            </Button>
            <Button
              onClick={() => {
                setSubmitModal(true);
              }}
              style={{
                background:
                  formData && formData.Status && formData.Status === 'Submitted'
                    ? '#8b8e91'
                    : '#1890ff',
                color:
                  formData && formData.Status && formData.Status === 'Submitted'
                    ? '#0a0a0b'
                    : '#fff'
              }}
              disabled={
                ( formData && formData.Status && formData.Status === 'Saved')
                ||
                 (formData && formData.Id && formData.Id !== null)
                 ? false : true
               }
            >
              {formData && formData.Status && formData.Status === 'Submitted'
                ? translation('Submitted')
                : translation('Submit')}
            </Button>
          </div>
        </StyledForm>
      </div>
      <StyledModal
        width="50%"
        // title={this.translation('Confirm the Acton')}
        visible={submitModal}
        okText={translation('Submit')}
        cancelText={translation('Cancel')}
        onOk={onSubmitReport}
        onCancel={() => {
          setSubmitModal(false);
        }}
        // closable
        theme={themeSettings}
      // footer={null}
      >
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <p style={{ fontSize: 'large', color: 'white' }}>
            {' '}
            {translation('Are you sure, you want to submit daily report?')}
          </p>
        </div>
      </StyledModal>
    </StyledReport>
  );
};
const mapStateToProps = createStructuredSelector({
  userProfile: getUserProfile(),
  translationCache: getTranslationCache(),
  currentTimeZone: getCurrentTimezone()
});
export default connect(mapStateToProps)(App);
// export default App;

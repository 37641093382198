export const IPAddress = {
  LOCAL: 'http://20.198.16.173:8800',
  PREDICTIONIP: 'http://192.168.95.23:7500'
};

export const API_AUTH = {
  LOGIN: '/api/auth/login',
  ME: '/api/auth/me',
  SignUp: '/api/auth/signup',
  ChangePassword: '/api/auth/changePassword',
  ChangeFirstPassword: '/api/auth/changeFirstPassword',
  UpdateTokenStatus: '/api/auth/updateTokenStatus',
  ResetPassword: '/api/auth/resetPassword'
};

export const API_ASSET = {
  CACHE: '/api/asset/cache',
  GET: '/api/asset/get',
  CREATE: '/api/asset/create',
  UPDATE: '/api/asset/update',
  DELETE: '/api/asset/delete',
  SAVEFILE: '/api/asset/saveFile',
  DOWNLOADFILE: '/api/asset/downloadFile',
  CHECKDUPLICATE: '/api/asset/checkDuplicates',
  GETASSETBYMODULE: '/api/asset/getAssetByModule',
  GETDEVICEANDSUBLOCATIONBYId: '/api/hierarchymodule/getDataByHierarchyId',
  SAVE: '/api/asset/save',

  CLIENT_GET: '/api/client/asset/get',
  CLIENT_UPDATE: '/api/client/asset/update',
  CLIENT_CREATE: '/api/client/asset/create'
};
export const API_STATUSCONFIGURATION = {
  AssetGET: '/api/hierarchymodule/getHierarchyLevels'
};
export const API_DEVICE = {
  CACHE: '/api/device/cache',
  GET: '/api/device/get',
  LIST: '/api/device/list',
  CREATE: '/api/device/create',
  UPDATE: '/api/device/update',
  DELETE: '/api/device/delete',
  CHECKDUPLICATES: '/api/device/checkDuplicates',
  GENERATESCANNERID: '/api/device/generateScannerId',
  GetDeviceParamsByCategory: '/api/device/getDeviceParamsByCategory',
  GetDevicesByParamCategory: '/api/device/getDevicesByParamCategory',
  GetParamsByDeviceType: '/api/device/getParamsByDeviceType',
  GetDeviceParamsByType: '/api/device/getDeviceParamsByType',
  GetCraneStatus: '/api/device/getCraneStatus',
  GetCraneStatusCount: '/api/device/getCraneStatusCount',
  GetCraneDetails: '/api/device/getCraneDetails',
  GetStatusDetails: '/api/device/getStatusDetails',
  GetPopupDetails: '/api/device/getPopupDetails',
  GetTableDetails: '/api/device/getTableDetails',
  GetScannersList: '/api/device/getScannersList',
  UpdateScannerId: '/api/device/updateScannerId',
  GetScannerDetails: '/api/device/getScannerDetails',
  GetDeviceTypes: '/api/device/getDeviceTypes',
  GetDeviceByType: '/api/device/getDeviceByType',
  GETDevices: '/api/client/device/get',
  CreateDevice: '/api/client/device/create',
  UpdateDevice: '/api/client/device/update'
};
export const API_RUBUSMASTERS = {
  GET: '/api/rubusMaster/devices/get',
  DT_GET: '/api/rubusMaster/deviceType/get',
  ParametersGet: '/api/rubusMaster/parameters/get',
  AssetAttributesUPDATE: '/api/rubusMaster/assetAttributes/update',
  AssetAttributesCREATE: '/api/rubusMaster/assetAttributes/create',
  AssetAttributesDELETE: '/api/rubusMaster/assetAttributes/delete',
  AssetTypeCREATE: '/api/rubusMaster/assetType/create',
  AssetTypeUPDATE: '/api/rubusMaster/assetType/update',
  AssetTypeDELETE: '/api/rubusMaster/assetType/delete',
  DynamicHierarchyCREATE: '/api/rubusMaster/dynamicHierarchy/create',
  DynamicHierarchyUPDATE: '/api/rubusMaster/dynamicHierarchy/update',
  DynamicHierarchyDELETE: '/api/rubusMaster/dynamicHierarchy/delete'
};

export const API_DEVICETYPE = {
  GET: '/api/deviceType/get',
  CACHE: '/api/deviceType/cache',
  CREATE: '/api/deviceType/create',
  UPDATE: '/api/deviceType/update',
  DELETE: '/api/deviceType/delete',
  CMDevices: '/api/deviceType/cmdevices',
  GetDeviceTypeListByAsset: '/api/rubusMaster/deviceType/getDeviceTypeByAsset'
};

export const API_PARAMETER = {
  GET: '/api/parameter/get',
  LIST: '/api/parameter/list',
  CREATE: '/api/parameter/create',
  UPDATE: '/api/parameter/update',
  DELETE: '/api/parameter/delete',
  RESET: '/api/parameter/reset',
  DBScriptRun: '/api/parameter/dbScriptRun',
  GetParameters: '/api/parameter/getParameters',
  UpdateTopics: '/api/parameter/updateTopics',
  CHECKDUPLICATES: '/api/parameter/checkDuplicates',
  GetUOM: '/api/parameter/getUOM'
};

export const API_DEVICEPARAMETER = {
  DeleteAlias: '/api/deviceParameter/deleteAlias',
  GetParametersByType: '/api/deviceParameter/getParametersByType',
  DeviceParameters: '/api/deviceParameter/deviceParameters',
  EnterAlias: '/api/deviceParameter/enterAlias',
  UpdateAlias: '/api/deviceParameter/UpdateAlias',
  GetParameterCategory: '/api/deviceParameter/getParameterCategory',
  GetParameterTypes: '/api/deviceParameter/getParameterTypes',
  GetParameterGroups: '/api/deviceParameter/getParameterGroups'
};

export const API_MASTERCONFIGURATION = {
  GET: '/api/masterconfiguration/get',
  CREATE: '/api/masterconfiguration/create',
  UPDATE: '/api/masterconfiguration/update',
  DELETE: '/api/masterconfiguration/delete',
  Master: '/api/masterconfiguration/master',
  GETDROPDOWN: '/api/masterconfiguration/getDropdown',
  GetTableList: '/api/masterconfiguration/getTableList',
  GetColumnNamesByTableName: '/api/masterconfiguration/getColumnNamesByTableName',
  GetDropdownForCompositeKey: '/api/masterconfiguration/getDropdownForCompositeKey'
};

export const API_DASHBOARD = {
  GetDashboard: '/api/dashboard/getDashboard',
  GetAlertsCount: '/api/dashboard/getAlertsCount',
  GetAlertsData: '/api/dashboard/getAlertsData',
  Feedback: '/api/dashboard/feedback',
  GET: '/api/dashboard/get',
  CREATE: '/api/dashboard/create',
  UPDATE: '/api/dashboard/update',
  DELETE: '/api/dashboard/delete',
  GetNameByGroup: '/api/dashboard/getNameByGroup',
  GetSmartFormData: '/api/dashboard/getSmartFormData',
  SmartControlForm: '/api/dashboard/smartControlForm',
  VibratingMotorForm: '/api/dashboard/vibratingMotorForm'
};

export const API_TRENDS = {
  LIST: '/api/trends/list',
  GetOperators: '/api/trends/getOperators',
  GetParametersData: '/api/trends/getParametersData'
};

export const API_RULEENGINE = {
  GET: '/api/ruleEngine/get',
  CREATE: '/api/ruleEngine/create',
  UPDATE: '/api/ruleEngine/update',
  DELETE: '/api/ruleEngine/delete',
  GetRuleStatus: '/api/ruleEngine/getRuleStatus',
  SaveFeedbackData: '/api/ruleEngine/createFeedback',
  GetFeedbackList: '/api/ruleEngine/getFeedback',
  UpdateFeedback: '/api/ruleEngine/updateFeedback',
  GetStatusCount: '/api/ruleEngine/getStatusCount',
  GetRestApiList: '/api/ruleEngine/getRestApiList',
  UpdateNotificationStatus: '/api/ruleEngine/updateNotificationStatus',
  UpdateRuleStatus: '/api/ruleEngine/updateRuleStatus',
  GetNonDeviceParametersList: '/api/ruleEngine/getNonDeviceParametersList',
  GetTableList: '/api/ruleEngine/getTableList',
  GetColumnList: '/api/ruleEngine/getColumnList',
  GetPriorityList: '/api/ruleEngine/getPriorityList',
  GetRuleEngineLogs: '/api/ruleEngine/getRuleEngineLogs',
  GetWarningTypes: '/api/ruleEngine/getWarningTypes',
  GetRootCause: '/api/ruleEngine/getRootCause',
  GetRuleTypes: '/api/ruleEngine/getRuleTypes'
};

export const API_MAINTAINENECE = {
  GET: '/api/maintenance/get',
  CREATE: '/api/maintenance/create',
  UPDATE: '/api/maintenance/update',
  DELETE: '/api/maintenance/delete'
};

export const API_LANGUAGE = {
  LanguageFilter: '/api/language/LanguageFilter',
  LanguageList: '/api/language/languageList',
  BULK: '/api/language/bulk',
  GET: '/api/language/get',
  Language: '/api/language'
};

export const API_MASTERWORKORDERS = {
  LandingPageCount: '/api/workOrders/getCount',
  RoleBasedList: '/api/workOrders/roleBasedList',
  Status: '/api/workOrders/status',
  SaveFile: '/api/workOrders/saveFile',
  List: '/api/workOrders/list',
  GetById: '/api/workOrders/getById',
  Delete: '/api/workOrders/delete',
  GetId: '/api/workOrders/generateId',
  Insert: '/api/workOrders/upsert',
  DOWNLOADFILE: '/api/workOrders/downloadFile',
  UpdateStatus: '/api/workorders/updateStatus',
  UpdateWoFlowStatus: '/api/workOrders/updateWoFlowStatus',
  ChangeStatus: '/api/workOrders/updateStatus'
};

export const API_MASTERASSetDETAILS = {
  INSERT: '/api/amm/device/upsert',
  GetEquipmentsById: '/api/amm/device/getById',
  GetEquipmentOverviewDetailsById: '/api/amm/device/getOverviewById'
};

export const API_MASTERITEMS = {
  INSERT: '/api/items/upsert',
  LIST: '/api/items/list',
  GetById: '/api/items/getById',
  DELETE: '/api/items/delete',

  GET: '/api/masterItems/get'
};

export const API_HIERARCHYMODULE = {
  GetHierarchyLevels: '/api/hierarchymodule/getHierarchyLevels',
  GetDefaultHierarchy: '/api/hierarchymodule/getDefaultHierarchy',
  GetHierarchyLevelsForFaults: '/api/hierarchymodule/getHierarchyLevelsForFaults',
  Dropdown: '/api/hierarchymodule/drop-down',
  GetHierarchyLevelsByCode: '/api/hierarchymodule/getHierarchyLevelsByCode',
  CreateVirtualHierarchies: '/api/hierarchymodule/createVirtualHierarchies',
  CREATE: '/api/hierarchymodule/create',
  UPDATE: '/api/hierarchymodule/update',
  DELETE: '/api/hierarchymodule/delete',
  GetById: '/api/hierarchymodule/getById',
  Hierarchyfileupload: '/api/hierarchymodule/hierarchyfileupload',
  MainHierarchyList: '/api/hierarchymodule/mainHierarchyList',
  GetMaximoHierarchy: '/api/hierarchymodule/getMaximoHierarchy',
  HierarchyLevels: '/api/hierarchymodule/hierarchyLevels',
  MainHierarchyCreate: '/api/hierarchymodule/mainHierarchyCreate',
  MainHierarchyUpdate: '/api/hierarchymodule/mainHierarchyUpdate',
  MainHierarchyDelete: '/api/hierarchymodule/mainHierarchyDelete',
  GetModuleHierarchy: '/api/hierarchymodule/getModuleHierarchy',
  GetDeviceTypeHierarchy: '/api/hierarchymodule/getDeviceTypeHierarchy'
};

export const API_MASTERFAILURECODES = {
  LIST: '/api/failureCodes/list',
  GetById: '/api/failureCodes/getById',
  DELETE: '/api/failureCodes/delete',
  Hierarchy: '/api/failureCodes/getHierarchy',
  INSERT: '/api/failureCodes/upsert'
};

export const API_INSPECTIONQUESTIONS = {
  LIST: '/api/inspectionQuestions/get',
  GetById: '/api/inspectionQuestions/getById',
  DELETE: '/api/inspectionQuestions/delete',
  Master: '/api/inspection/addQuestion',
  INSERT: '/api/inspection/saveQuestion',
  GetQuestionsList: '/api/inspection/getQuestions'
};

export const API_INSPECTIONCONFIG = {
  GET: '/api/inspection/get',
  GetById: '/api/inspection/getById',
  DELETE: '/api/inspectionconfig/delete',
  Master: '/api/inspectionconfig/master',
  GetId: '/api/inspectionconfig/getId',

  Getinspectionswithkey: '/api/inspection/getByKeys',
  UpdateInsStatus: '/api/inspectionData/updateStatus',
  GetInspectionQuestions: '/api/inspectionData/getQuestions',
  InsertLogbookInspection: '/api/inspectionData/insert',
  CronInspectionList: '/api/inspectionData/cronInspectionList',
  GetByInspectionId: '/api/inspectionData/getById',
  InspectionHierarchy: '/api/inspectionData/GetHierarchy',
  INSERT: '/api/inspection/upsert'
};

export const API_MASTERLABOUR = {
  LIST: '/api/labour/list',
  GetById: '/api/labour/getById',
  INSERT: '/api/labour/upsert',
  DELETE: '/api/labour/delete',

  GetId: '/api/masterLabor/getId'
};

export const API_MASTERMATERIALS = {
  LIST: '/api/materials/list',
  GetById: '/api/materials/getById',
  INSERT: '/api/materials/upsert',
  DELETE: '/api/materials/delete'
};

export const API_MASTERTOOL = {
  LIST: '/api/tools/list',
  GetById: '/api/tools/getById',
  INSERT: '/api/tools/upsert',
  DELETE: '/api/tools/delete'
};

export const API_SITE = {
  GET: '/api/site/get',
  CREATE: '/api/site/create',
  DELETE: '/api/site/delete',
  UPDATE: '/api/site/update',
  GetById: '/api/site/getById',
  LogoUpload: '/api/site/logoUpload'
};

export const API_HIERARCHY = {
  LIST: '/api/hierarchy/LIST',
  GET: '/api/hierarchy/GET',
  CREATE: '/api/hierarchy/create',
  DELETE: '/api/hierarchy/delete',
  UPDATE: '/api/hierarchy/update'
};

export const API_APPCONFIGATTACHMENTS = {
  AppConfigAttachments: '/api/eamAttachments'
};

export const API_SERVICEREQ = {
  DELETE: '/api/SR/delete',
  LIST: '/api/SR/list',
  INSERT: '/api/SR/upsert',
  GETID: '/api/SR/getId',
  GETBYID: '/api/SR/getById',
  ChangeStatus: '/api/SR/updateStatus'
};

export const API_CONDITIONALMONITORING = {
  DELETE: '/api/CM/delete',
  LIST: '/api/CM/list',
  INSERT: '/api/CM/insert',
  GETBYID: '/api/CM/getById'
};

export const API_MASTERJOBPLAN = {
  DELETE: '/api/jobPlans/delete',
  LIST: '/api/jobPlans/list',
  INSERT: '/api/jobPlans/upsert',
  GETBYID: '/api/jobPlans/getById',
  ChangeStatus: '/api/jobPlans/updateStatus'
};

export const API_PM = {
  DELETE: '/api/pm/delete',
  LIST: '/api/pm/list',
  INSERT: '/api/pm/insert',
  GETBYID: '/api/pm/getById',
  GETID: '/api/pm/getId',
  ChangeStatus: '/api/pm/changeStatus'
};

export const MASTERTASK = {
  DELETE: '/api/tasks/delete',
  LIST: '/api/tasks/list',
  INSERT: '/api/tasks/upsert',
  GETBYID: '/api/tasks/getById',
  ChangeStatus: '/api/tasks/updateStatus'
};

export const MASTERWORKFLOW = {
  GET: '/api/workflow/get',
  DELETE: '/api/workflow/delete',
  INSERT: '/api/workflow/insert',
  UPDATE: '/api/workflow/update',
  WorkFlowList: '/api/workflow/list',
  // api/workflow/list
  GETBYID: '/api/workflow/getById',
  Json: '/api/workflow/getJsonById'
};
export const API_SCHEDULER = {
  LIST: '/api/scheduler/list',
  INSERT: '/api/scheduler/upsert',
  GETBYID: '/api/scheduler/getById'
};
export const API_ORGANISATION = {
  GET: '/api/organisation/get'
};
export const LOCATION = {
  LIST: '/api/location/list'
};
export const GETUSERLIST = {
  GET: '/api/user/get',
  UserList: '/api/user/userlist',
  List: '/api/user/list',
  UPDATE: '/api/user/update',
  CREATE: '/api/user/create',
  Delete: '/api/user/delete',
  LogoUpload: '/api/user/logoUpload'
};
export const INSPECTION = {
  GetByInspectionByEquipment: '/api/inspection/getByDevice',
  Hierarchy: '/api/inspection/gethierarchy'
};
export const MASTER = {
  DropDown: '/api/master/dropdown',
  FileUpload: '/api/master/fileupload',
  DeleteFile: '/api/master/deletefile',
  UpdateStatus: '/api/master/updatestatus'
};
export const MASTER_Mapping = {
  GET: '/api/mastermapping/get',
  CREATE: '/api/mastermapping/create',
  UPDATE: '/api/mastermapping/upadte',
  Delete: '/api/mastermapping/delete'
};
export const MASTERTABLECONFIGURATION = {
  Master: '/api/mastertableconfiguration/master'
};
export const ANALYTICS = {
  GET: '/api/analytics/get',
  GETBYID: '/api/analytics/getById',
  UPDATE: '/api/analytics/update',
  DELETE: '/api/analytics/delete',
  DeleteAttachment: '/api/analytics/deleteAttachment',
  CREATE: '/api/analytics/create',
  UsecaseCreate: '/api/usecase/usecaseCreate',
  FileUpload: '/api/analytics/fileupload',
  Attachment: '/api/analyticsAttachments',
  UseCaseGet: '/api/usecase/usecaseGet',
  UseCaseUpdate: '/api/usecase/usecaseUpdate',
  PredictionGetById: '/api/usecase/predictionGetById',
  PredictionGet: '/api/usecase/predictionGet',
  PredictionUpdate: '/api/usecase/predictionUpdate',
  PredictionCreate: '/api/usecase/predictionCreate',
  TrainingJobCreate: '/api/usecase/trainningJobCreate',
  TrainningJobUpdate: '/api/usecase/trainningJobUpdate',
  TrainingJobGet: '/api/usecase/trainningJobGet',
  TrainingJobGetById: '/api/usecase/trainningJobGetbyId',
  Parameterslist: '/api/usecase/parameterslist'
};
export const API_DOWNLOAD = {
  GetFiles: '/api/download/getFiles',
  GenerateVersionsDevices: '/api/download/generateVersionsDevices',
  GenerateVersionsAssets: '/api/download/generateVersionsAssets',
  GenerateVersionsParams: '/api/download/generateVersionsParams',
  GenerateExcelAssets: '/api/download/generateExcelAssets',
  GenerateExcelParams: '/api/download/generateExcelParams',
  generateExcelDevices: '/api/download/generateExcelDevices',
  DownLoads: '/api/downloads',
  DownloadAMS: '/api/downloads/ams',
  DownLoadsParamDevice: '/api/downloads/configurations'
};
export const VERSIONS = {
  CONFIGURATIONS: '/api/downloads/configurations',
  Versions: '/api/versions'
};
export const VALIDATION = {
  DEVICE: '/api/validation/device',
  ASSET: '/api/validation/asset',
  PARAMETER: '/api/validation/parameter',
  DeviceValidation: '/api/validation/deviceValidation',
  AssetValidation: '/api/validation/assetValidation',
  ParameterValidation: '/api/validation/parameterValidation'
};
export const UPLOAD = {
  ASSETBULK: '/api/upload/assetBulk',
  DEVICEBULK: '/api/upload/deviceBulk',
  PARAMETERBULK: '/api/upload/parameterBulk',
  BULKUPLOAD: '/api/upload/bulkUpload',
  Uploads: '/api/uploads',
  Images: '/api/uploads/images'
};
export const DATAWATCH = {
  TOPLICLIST: '/api/datawatch/topicList',
  GROUPLIST: '/api/datawatch/groupList',
  GETPARAMETR: '/api/datawatch/getParameters'
};
export const TOPIC = {
  DOWNLOAD: '/api/topic/download',
  UPLOAD: '/api/topic/upload',
  GET: '/api/topic/get',
  CREATE: '/api/topic/create',
  DELETE: '/api/topic/delete',
  GetTopicTypes: '/api/topic/getTopicTypes',
  GetRedisDB: '/api/topic/getredisDb',
  UPDATE: '/api/topic/update'
};
export const WARNINGLIMITS = {
  GetWarningData: '/api/WarningLimits/getWarningData'
};
export const PREDICTIONS = {
  UPDATE: '/api/predictions/update'
};
export const REPORTS = {
  Reports: '/api/reports',
  GenerateExcel: '/api/report/generateExcel',
  ReportsList: '/api/report/reportsList',
  Get: '/api/report/get',
  GetReport: '/api/report/getReport',
  GeneratePDF: '/api/file/generatePdf',
  CREATE: '/api/report/create',
  UPDATE: '/api/report/update',
  InsertJSON: '/api/report/insertJSON',
  Delete: '/api/report/delete'
};
export const LANDINGPAGE = {
  LandingPageDeviceStatus: '/api/landingPage/landingpagedeviceStatus',
  GET: '/api/landingPage/get',
  DELETE: '/api/landingPage/delete',
  CREATE: '/api/landingPage/create',
  UPDATE: '/api/landingPage/update'
};
export const LOGBOOK = {
  DropDown: '/api/logbook/dropdown',
  GET: '/api/logbook/get',
  Submit: '/api/logbook/submit',
  TableList: '/api/logbook/tableslist',
  UPDATE: '/api/logbook/update',
  CREATE: '/api/logbook/Create',
  LogBookSubmit: '/api/logbook/logbooksubmit',
  EditList: '/api/logbook/editlist',
  EquimentList: '/api/logbook/equipmentlist',
  Save: '/api/logbook/save',
  SalesLogBookSubmit: '/api/logbook/salesLogbookSubmit',
  Recurrence: '/api/logbook/recurrence',
  GetLogBookById: '/api/logbook/getLogBookById',
  ManualFormUpload: '/api/logbook/manualFormUpload',
  AttributesList: '/api/logbook/attributeslist',
  GetDeviceData: '/api/logbook/getDeviceData',
  GetId: '/api/logbook/getId',
  Copy: '/api/logbook/copy',
  GetGlazelinelist: '/api/logbook/getGlazelinelist',
  LogBookConfigurationByLogBookId: '/api/logbook/logBookConfigurationByLogbookId',
  GetFinalEntry: '/api/logbook/getFinalEntry',
  DropdownForColumnaliasNames: '/api/logbook/dropdownforcolumnaliasnames'
};
export const ATTACHMENTS = {
  EamAttachments: '/api/eamAttachments'
};
export const SAMPLE = {
  ASSET: '/api/sample/asset.csv',
  DEVICE: '/api/sample/device.csv',
  PARAMETER: '/api/sample/parameter.csv',
  EquimentParts: '/api/sample/EquipmentParts_sample.xlsx',
  Sample: '/api/sample',
  Okumura: '/api/sample/Okumura.xlxs',
  SampleCSV: '/api/sample/translation.csv'
};
export const API_FILE = {
  GenerateExcel: '/api/file/generateExcel'
};
export const DOWN_TIME = {
  Area: '/api/downtime/getDepartment',
  Type: '/api/downtime/getDowntimeType',
  Shift: '/api/downtime/getMasterShifts',
  FailureList: '/api/downtime/getFailureCodes',
  FailureDetails: '/api/downtime/failureDetails',
  CREATE: '/api/downtime/upsert',
  GET: '/api/downtime/get',
  // UPDATE: '/api/downtime/update',
  GetShiftMaster: '/api/downtimeLog/getShiftMaster',
  GetSubEquipment: '/api/downtimeLog/getSubequipment',
  Submit: '/api/downtime/submit'
};
export const API_ROLE = {
  GET: '/api/role/get',
  Permissions: '/api/role/permissions',
  UPDATE: '/api/role/update',
  CREATE: '/api/role/create',
  DELETE: '/api/role/delete'
};
export const SUPPLIER = {
  GET: '/api/supplier/get',
  CREATE: '/api/supplier/create',
  UPDATE: '/api/supplier/update',
  DELETE: '/api/supplier/delete'
};
export const SPECDATA = {
  GET: '/api/specData/get',
  Upsert: '/api/specData/upsert',
  Upsert1: '/api/specData/upsert1',
  ListAll: '/api/specData/listAll'
};
export const PARTS = {
  GET: '/api/parts/get',
  GenerateExcel: '/api/parts/generateExcel',
  Upsert: '/api/parts/upsert',
  UPLOAD: '/api/parts/upload',
  PartLevelUpload: '/api/parts/partlevelUpload',
  PartsList: '/api/parts/partsList'
};
export const PLANTMAINTENANCE = {
  CREATE: '/api/plantmaintenance/create',
  GET: '/api/plantmaintenance/get',
  GetMasterTasksById: '/api/plantmaintenance/getMasterTasksById',
  DELETE: '/api/plantmaintenance/delete'
};
export const PARTSDATA = {
  Upsert: '/api/partsData/upsert',
  GET: '/api/partsData/get',
  Locations: '/api/partsData/locations',
  Delete: '/api/partsData/delete'
};
export const PARTPARAMETERTYPES = {
  GET: '/api/partParameterTypes/get'
};
export const API_DEVICETYPESTATUS = {
  GET: '/api/deviceTypeStatus/get',
  CREATE: '/api/deviceTypeStatus/create',
  UPDATE: '/api/deviceTypeStatus/update',
  DELETE: '/api/deviceTypeStatus/delete'
};
export const DEVICESPECIFICATIONS = {
  GET: '/api/deviceSpecifications/get',
  CREATE: '/api/deviceSpecifications/create',
  UPDATE: '/api/deviceSpecifications/update',
  DELETE: '/api/deviceSpecifications/delete'
};
export const API_EQUIPMENTDOCS = {
  GET: '/api/equipmentDocs/get',
  DELETE: '/api/equipmentDocs/delete',
  CREATE: '/api/equipmentDocs/create'
};
export const EVENTS = {
  GET: '/api/events/get',
  CREATE: '/api/events/create',
  VALIDATION: '/api/eventStatusMapping/validation'
};
export const FFT = {
  Generate: '/api/fft/generate',
  Show: '/api/fft/show',
  GetFiles: '/api/fft/getFiles'
};
export const PAGE = {
  GET: '/api/page/get'
};
export const API_HMI = {
  GET: '/api/hmi/get',
  CREATE: '/api/hmi/create',
  INSERT: '/api/hmi/insert',
  DELETE: '/api/hmi/delete',
  UPDATE: '/api/hmi/update',
  LIST: '/api/hmi/list',
  HmiModal: '/api/hmi/hmiModal',
  SVGUpload: '/api/hmi/svgupload'
};
export const API_QUERYBUILDER = {
  GetTableList: '/api/queryBuilder/getTablesList',
  GetSchema: '/api/queryBuilder/getSchema',
  GetFunctionsDropDown: '/api/queryBuilder/getFunctionsDropdown',
  OperatorList: '/api/queryBuilder/operatorList',
  CREATEQUERY: '/api/queryBuilder/createQuery',
  GetColumns: '/api/queryBuilder/'
};
export const MAXIMOASSET = {
  Download: '/api/ams/download/assetDevice',
  List: '/api/ams/mapping/assetDeviceList',
  DeviceList: '/api/maximoasset/deviceList',
  FaultCodeList: '/api/ams/getParamsByDevice',
  UpdateAFCDetails: '/api/ams/updateParameter',
  GetAFCList: '/api/ams/getParamsByModule',
  GetMaximoHierarchy: '/api/ams/getSRHierarchyByModule',
  Upload: '/api/ams/upload/assetDevice',
  DeviceHierarchy: '/api/ams/getMeterHierarchy',
  ParameterByDevice: '/api/ams/getMeterParamsByDevice',
  IntervalList: '/api/ams/getIntervals',
  UpdateIntervals: '/api/ams/updateIntervals',
  Edit: '/api/ams/getAmsParameterById',

  ParameterList: '/api/ams/mapping/paramDeviceList',
  ParameterDownload: '/api/ams/download/paramDevice',
  ParameterUpload: '/api/ams/upload/paramDevice'
};
export const MAXIMO = {
  Download: '/api/ams/download/assetDeviceParameter',
  GET: '/api/maximo/get',
  List: '/api/ams/mapping/assetDeviceParameterList',
  UPLOAD: '/api/ams/upload/assetDeviceParameter'
};
export const FEEDBACK = {
  INSERT: '/api/feedback/insert'
};
export const EDGECONFIG = {
  GET: '/api/edgeConfig/get',
  Historian: '/api/edgeConfig/historian',
  GetHistorian: '/api/edgeConfig/gethistorian',
  CREATE: '/api/edgeConfig/create',
  UPDATE: '/api/edgeConfig/update',
  DELETE: '/api/edgeConfig/delete',
  CheckHistorian: '/api/edgeConfig/checkHistorian',
  BULKUPLOAD: '/api/edgeConfig/bulkUpload'
};
export const NOTIFICATIONS = {
  GET: '/api/amsNotifications/List'
};
export const IHSR = {
  Excel: '/api/IHSR/excel',
  Get: '/api/IHSR/get'
};
export const EDGE = {
  List: '/api/edge/list',
  CREATE: '/api/edge/create',
  Get: '/api/edge/get'
};
export const XMLFORMAT = {
  GetById: '/api/xmlFormat/getById',
  UniqueColumnsByXmlData: '/api/xmlformat/KeysByXmlData',
  CREATE: '/api/xmlFormat/create',
  Get: '/api/xmlFormat/get'
};
export const SELECTEDFILE = {
  DataEntryUpload: '/api/SelectedFile/DataEntryUpload'
};
export const OVERVIEW = {
  GetStatus: '/api/overview/getStatus',
  UpdateStatus: '/api/overview/updateStatus'
};
export const API_MENU = {
  Get: '/api/menu/get',
  CREATE: '/api/menu/create',
  Update: '/api/menu/update',
  DELETE: '/api/menu/delete',
  BULKUPLOAD: '/api/menu/bulkUpload'
};
export const DEPARTMENT = {
  GET: '/api/department/get',
  DELETE: '/api/department/delete',
  CREATE: '/api/department/create',
  UPDATE: '/api/department/update'
};
export const BOOKMARK = {
  GET: '/api/bookmark/get',
  CREATE: '/api/bookmark/create',
  UPDATE: '/api/bookmark/update',
  Delete: '/api/bookmark/delete'
};
export const MEMBER = {
  CREATE: '/api/member/create',
  Update: '/api/member/update',
  Get: '/api/member/get',
  Delete: '/api/member/delete'
};
export const OBSERVATION = {
  GET: '/api/observation/get',
  CREATE: '/api/observation/create',
  DELETE: '/api/observation/delete'
};
export const MASTERIMAGES = {
  MasterImages: '/api/masterImages',
  ECH_PNG: '/api/masterImages/ECH.png',
  RS_PNG: '/api/masterImages/RS.png',
  ITV_PNG: '/api/masterImages/ITV.png',
  GISTRACKING_PNG: '/api/masterImages/GIS Tracking.png',
  ST_MHC_V1_PNG: '/api/masterImages/ST_MHC_V1.png',
  ST_QC_V1_PNG: '/api/masterImages/ST_QC_V1.png',
  ST_RTG_V1_PNG: '/api/masterImages/ST_RTG_V1.png',
  MHC_RUNNING_V1_PNG: '/api/masterImages/MHC_Running_v1.png',
  MHC_IDLE_V1_PNG: '/api/masterImages/MHC_Idle_v1.png',
  Moblie_Harbour_Crane_V3_PNG: '/api/masterImages/Mobile_Harbour_Crane_V3.png',
  MHC_Alert_V1_PNG: '/api/masterImages/MHC_Alert_v1.png',
  QC_RUNNING_V3_PNG: '/api/masterImages/QC_Running_V3.png',
  QC_IDLE_V3_PNG: '/api/masterImages/QC_Idle_V3.png',
  QC1_V3_PNG: '/api/masterImages/QC1_V3.png',
  RTG_RUNNING_V3_PNG: '/api/masterImages/RTG_Running_V3.png',
  RTG_Idle_V3_PNG: '/api/masterImages/RTG_Idle_V3.png',
  RTG_CRANE_V3_PNG: '/api/masterImages/RTG_crane_V3.png',
  RTG_Alert_V3_PNG: '/api/masterImages/RTG_Alert_V3.png',
  BLUE_TURBINE: '/api/svg_masters/Farm_blue_V1.png',
  GREEN_TURBINE: '/api/svg_masters/Farm_green_V1.png',
  YELLOW_TURBINE: '/api/svg_masters/Farm_yellow_V1.png',
  RED_TURBINE: '/api/svg_masters/Farm_red_V1.png'
};
export const REPORTFRAMEWORK = {
  JobNames: '/api/reportframework/jobnames',
  ReRunHistory: '/api/cronRerun/rerunHistory',
  ReRun: '/api/cronRerun/rerun'
};
export const TIMESCALE = {
  UserGroupsMaster: '/api/timeScale/userGroupsMaster',
  TableConfig: '/api/timeScale/tableconfig'
};
export const RBSPREDMODEL = {
  ReadMetadata: '/api/RbsPredModel/ReadMetadata',
  RegisterModel: '/api/RbsPredModel/Registermodel',
  DeployemtReqlist: '/api/RbsPredModel/deployemtreqlist',
  PredictionReqlist: '/api/RbsPredModel/predictionreqlist',
  MetricpRedList: '/api/RbsPredModel/metricpredlist',
  PredictionResList: '/api/RbsPredModel/PredictionResList',
  RelatedTagsValuesList: '/api/RbsPredModel/relatedTagsValueslist',
  DeviceParameters: '/api/RbsPredModel/deviceParameters',
  PredictImages: '/api/RbsPredModel/predictimages',
  FunctionalSummaryWeek: '/api/RbsPredModel/functionalSummaryWeek',
  FunctionalSummaryDayWise: '/api/RbsPredModel/functionalSummaryDayWise',
  StagingmetadataList: '/api/RbsPredModel/stagingmetadatalist',
  ModelWorkBenchList: '/api/RbsPredModel/modelworkbenchlist',
  CompletedPredictionRequestList: '/api/RbsPredModel/CompletedPredictionRequestList'
};
export const RBSLOOKUPMaster = {
  LookUpItemsGetById: '/api/RbsLookupMaster/lookupitemsgetbyId',
  GET: '/api/RbsLookupMaster/get',
  GetById: '/api/RbsLookupMaster/getById',
  CREATE: '/api/RbsLookupMaster/create',
  Delete: '/api/RbsLookupMaster/delete'
};
export const REALTIMEPREDICTION = {
  RealTimeFunctionalSummaryWeek: '/api/realtimePrediction/realTimeWeekWise',
  RealTimeFunctionalSummaryDayWise: '/api/realtimePrediction/realTimeDayWise',
  RealTimePredictionList: '/api/realtimePrediction/realtimepredictionList',
  RealTimePredictionResList: '/api/realtimePrediction/realtimePredictionResList',
  RealTimeGraph: '/api/realtimePrediction/realTimeGraph',
  Create: '/api/realtimePrediction/create',
  GetAnalyticsActionLogs: '/api/realtimePrediction/getAnalyticsActionLogs',
  Get: '/api/realtimePrediction/get',
  GetQuickRunResult: '/api/realtimePrediction/getQuickRunResult'
};
export const MASTERDATASOURCE = {
  DatasourceList: '/api/MasterDataSource/datasourcelist',
  GET: '/api/MasterDataSource/get',
  CREATE: '/api/MasterDataSource/create'
};
export const MASTERALGORITHM = {
  GET: '/api/algorithm/get',
  CREATE: '/api/algorithm/create',
  DELETE: '/api/algorithm/delete'
};
export const ServerRegistrationMasters = {
  GET: '/api/server/get',
  CREATE: '/api/server/create',
  DELETE: '/api/server/delete'
};
export const MODELCOMPARSION = {
  GET: '/api/modelcomparison/get',
  CREATE: '/api/modelcomparison/create',
  GetById: '/api/modelcomparison/getById'
};
export const DRIFT = {
  GET: '/api/drift/get',
  GetDevice: '/api/drift/getDevice',
  DriftParameters: '/api/drift/driftparameters',
  CREATE: '/api/drift/create',
  DataDrift: '/api/drift/DataDrift',
  SaveDriftResults: '/api/drift/SaveDriftResults'
};
export const MASTERUPLOAD = {
  DOWNLOAD: '/api/masterupload/download',
  Delete: '/api/masterupload/delete',
  UPLOAD: '/api/masterupload/upload'
};
export const RBSLOOKUPITEMS = {
  GET: '/api/RbsLookupItems/get',
  Delete: '/api/RbsLookupItems/delete'
};
export const MASTERWORKORDER = {
  DOWNLOADFILE: '/api/masterWorkOrder/downloadFile'
};
export const TROUBLEREPORT = {
  Download: '/api/troublereport/download',
  RetryVectorDB: '/api/troublereport/retryVectorDB',
  UPLOAD: '/api/troublereport/upload',
  GET: '/api/troublereport/get'
};
export const CHAT = {
  GET: '/api/chat/get',
  Create: '/api/chat/create',
  InsertConversation: '/api/chat/insertConversation',
  GetConversationById: '/api/chat/getConversationById',
  Delete: '/api/chat/delete',
  Debug: '/api/chat/debug',
  ClearChat: '/api/chat/clearChat',
  UpdateFeedback: '/api/chat/updateFeedback',
  GetQueryById: '/api/chat/getQueryById'
};
export const API_MODULES = {
  ModulesUpdate: '/api/rubusMaster/modules/update',
  ModulesDelete: '/api/rubusMaster/modules/delete',
  ModulesCreate: '/api/rubusMaster/modules/create',
  AttributesUpdate: '/api/rubusMaster/attributes/update',
  AttributesCreate: '/api/rubusMaster/attributes/create',
  ModulesGet: '/api/rubusMaster/modules/get',
  DeviceTypesUpdate: '/api/rubusMaster/deviceType/update',
  DeviceTypesCreate: '/api/rubusMaster/deviceType/create',
  DevicesUpdate: '/api/rubusMaster/devices/update',
  DevicesCreate: '/api/rubusMaster/devices/create',
  ParametersUpdate: '/api/rubusMaster/parameters/update',
  ParametersCreate: '/api/rubusMaster/parameters/create',
  AssetParametersCreate: '/api/rubusMaster/assetParameters/Create',
  AssetParametersUpdate: '/api/rubusMaster/assetParameters/update'
};
export const API_BACKUP = {
  TasksGet: '/api/backup/tasks/get',
  TasksGetById: '/api/backup/tasks/get',
  TasksGetTablesList: '/api/backup/tasks/getTablesList',
  TasksCreate: '/api/backup/tasks/create',
  TasksUpdate: '/api/backup/tasks/update',
  TasksDelete: '/api/backup/tasks/delete',
  ConfigCreate: '/api/backup/config/create',
  ConfigGetSchemas: '/api/backup/config/getSchemas',
  TasksGetSchemas: '/api/backup/tasks/getSchemas',
  TasksGetTablesBySchemas: '/api/backup/tasks/getTablesBySchemas',
  TaskStorage: '/api/backup/config/dataStorageLocation',
  PurgeGet: '/api/backup/purge/get',
  PurgeDelete: '/api/backup/purge/delete'
};
export const CUSTOM_REPORT = {
  Get: '/api/customReport/get',
  Upsert: '/api/customReport/upsert',
  RecipientsList: '/api/customReport/RecipientsList',
  CreateRecipients: '/api/customReport/createRecipients'
};

/**
 * @author Swathi
 * @description This Component is mainly for Creating Jobplans and Task
 * @View Table with crud operations of Jobplanes
 */
import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { message, Tree, Space, Form, DatePicker, Select } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { DownOutlined } from '@ant-design/icons';
import FileBase64 from '../../../Utils/FileUpload';
import {
  // getWorkOrderName,
  getQuestionDeviceList,
  getInspectionQuestions,
  getServiceReqId,
  submitInspection,
  getItemsListForInspection,
  getAreasList,
  getUsersList,
  getDataSourceOfInspectionById,
  submitMaster,
  getEquipmentHierarchyForInspection,
  getStatusListInspection
} from '../../../Utils/FormCalls';
import history from '../../../../../commons/history';
import { decode as base64_decode } from 'base-64';
import themeSettings from '../../../Utils/themeSettings.json';
import { getTranslationCache } from '../../../../../selectors/language';
import localStorage from '../../../../../utils/localStorage';
import { constant } from '../../../Utils/constants';
import { flattenDeep } from 'lodash';
import {
  StyledButton,
  StyledComponent,
  StyledCheckbox,
  StyledTree,
  StyledTable,
  StyledForm,
  StyledBox
} from '../../../CSS/style';
import translation from '../../../Utils/translation';
import TextAreaComponent from '../../../Widgets/TextAreaComponent';
import InputComponent from '../../../Widgets/InputComponent';
import { API_INSPECTIONCONFIG } from '../../../../../commons/api';
const { Option } = Select;
const type = 'Item';
const urlList = {
  insert: API_INSPECTIONCONFIG.UpdateInsStatus
};
class InspectionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      equipmentList: [],
      areaList: [],
      itemsList: [],
      visible: false,
      formObject:
        props.formObject && Object.keys(props.formObject).length > 0 ? props.formObject : {},
      datasource: [],
      EquipmentId: '',
      AreaId: '',
      gerSeviceReqId: '',
      InspectionId: '',
      QuestionId: '',
      mainid: '',
      Type: '',
      ResponsePayload: [],
      Remarks: '',
      InspectionLogBook: [],
      siteList: [],
      translationCache: props.translationCache || {},
      EquipmentKey: '',
      treeData: [],
      expandedKeys: [],
      fileUploadList: []
    };
    this.keys = this.getAllKeys(this.state.treeData);
  }
  formHeader = React.createRef();
  myRef = React.createRef();

  componentDidMount = async () => {
    this._getDataById();
    let equipmentList = await getQuestionDeviceList();
    let AreasList = await getAreasList();
    let ItemsList = await getItemsListForInspection();
    let usersList = await getUsersList();
    let statusList = await getStatusListInspection();
    this.setState({ equipmentList, AreasList, ItemsList, usersList, statusList });
  };

  getEquipmentHierarchy = async (InspectionId) => {
    let treeData = await getEquipmentHierarchyForInspection(InspectionId);
    if (treeData) {
      this.expandAll(treeData);
    }
    this.setState({ treeData, InspectionId });
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.translationCache !== this.props.translationCache) {
      this.setState({
        translationCache: this.props.translationCache
      });
    }
    if (prevProps.formObject !== this.props.formObject) {
      // if (this.props.formObject && !this.props.formObject.WorkOrder) {
      //   let workOrderName = await getWorkOrderName();
      //   this.handleChange(workOrderName['WorkOrder'], Object.keys(workOrderName)[0]);
      // }
      this.setState({
        formObject: this.props.formObject
      });
    }
  };

  _getDataById = async () => {
    let path = history.location.pathname.split('/rubus/AMM/InspectionDetails/');
    let formId = base64_decode(path[1]);
    if (formId !== 'new') {
      let formObject = await getDataSourceOfInspectionById(formId);
      _.cloneDeepWith(formObject, (value) => {
        return value && value !== undefined && value !== null
          ? {
              ...(value['InspectionCreationTime']
                ? (value['InspectionCreationTime'] = moment
                    .utc(value.InspectionCreationTime, 'YYYY-MM-DD HH:mm:ss')
                    .local())
                : null),
              ...(value['ActualEndDate']
                ? (value['ActualEndDate'] = moment.utc(value.ActualEndDate, 'YYYY-MM-DD HH:mm:ss'))
                : null)
            }
          : _.noop();
      });
      this.getEquipmentHierarchy(formId);
      this.setState({
        formObject
      });
      if (this.formHeader.current) {
        this.formHeader.current.setFieldsValue(formObject);
      }
    }
  };

  onFormsubmit = async (rowIndex) => {
    const siteId = localStorage.get('currentSite');
    let { dataRef, datasource, InspectionId, fileUploadList } = this.state;
    let payload = {};
    if (
      datasource &&
      datasource[rowIndex] &&
      datasource[rowIndex].payload &&
      datasource[rowIndex].payload.Option
    ) {
      let selectedOption = datasource[rowIndex].payload.Option;
      let Answers = datasource[rowIndex].Answers;

      let AnswerObject =
        Answers && Array.isArray(Answers) && Answers.filter((e) => e.Option === selectedOption);

      if (
        AnswerObject &&
        AnswerObject[0] &&
        AnswerObject[0].Actions &&
        Array.isArray(AnswerObject[0].Actions) &&
        AnswerObject[0].Actions[0]
      ) {
        if (
          AnswerObject[0].Actions[0] === 'ServiceRequest' &&
          datasource[rowIndex].Remarks &&
          datasource[rowIndex].Remarks.length > 0
        ) {
          if (datasource && datasource[rowIndex] && datasource[rowIndex].Answer) {
            let AnswersList = datasource[rowIndex].Answers || [];
            let selected = AnswersList.filter(
              (answersObject) => answersObject.Option === datasource[rowIndex].Answer
            );
            if (
              selected &&
              selected[0] &&
              selected[0].Actions &&
              Array.isArray(selected[0].Actions) &&
              selected[0].Actions[0] &&
              selected[0].Actions[0] === 'ServiceRequest'
            ) {
              let gerSeviceReqId = await getServiceReqId();
              datasource[rowIndex].ServiceReq = datasource[rowIndex].Answer;
              payload = {
                SiteId: siteId,
                SRId: gerSeviceReqId.SR
              };
            }
          }
          let fileList = fileUploadList && fileUploadList[rowIndex] ? fileUploadList[rowIndex] : [];

          let object = {
            Ciq_InspectionId: datasource[rowIndex].Id,
            Remarks: datasource[rowIndex].Remarks,
            Answer: datasource[rowIndex].Answer,
            ServiceReq: { ...payload },
            Attachments: datasource[rowIndex].Attachments
          };
          let InspectionResponse = await submitInspection(object, fileList, 'InspectionQuestion');
          if (InspectionResponse && InspectionResponse.message) {
            message.success(InspectionResponse.message);
            let questionPayload = {
              DeviceAlias: dataRef && dataRef['DeviceAlias'] ? dataRef['DeviceAlias'] : '',
              AreaId: dataRef && dataRef['ParentId'] ? dataRef['ParentId'] : '',
              ItemId: dataRef && dataRef['Code'] ? dataRef['Code'] : '',
              currentDate: moment().utc().format('YYYY-MM-DD'),
              Id: InspectionId ? InspectionId : ''
            };
            let datasource = await getInspectionQuestions(questionPayload, 'Questions');
            this.setState({ datasource, fileUploadList: { ...fileUploadList, [rowIndex]: [] } });
          } else if (InspectionResponse && InspectionResponse.Error) {
            message.error(InspectionResponse.Error);
          }
        } else {
          message.warn('Please enter remarks before submitting');
        }
      } else {
        let fileList = fileUploadList && fileUploadList[rowIndex] ? fileUploadList[rowIndex] : [];

        let object = {
          Ciq_InspectionId: datasource[rowIndex].Id,
          Remarks: datasource[rowIndex].Remarks,
          Answer: datasource[rowIndex].Answer,
          ServiceReq: { ...payload },
          Attachments: datasource[rowIndex].Attachments
        };
        let InspectionResponse = await submitInspection(object, fileList, 'InspectionQuestion');
        if (InspectionResponse && InspectionResponse.message) {
          message.success(InspectionResponse.message);
          let questionPayload = {
            DeviceAlias: dataRef && dataRef['DeviceAlias'] ? dataRef['DeviceAlias'] : '',
            AreaId: dataRef && dataRef['ParentId'] ? dataRef['ParentId'] : '',
            ItemId: dataRef && dataRef['Code'] ? dataRef['Code'] : '',
            currentDate: moment().utc().format('YYYY-MM-DD'),
            Id: InspectionId ? InspectionId : ''
          };
          let datasource = await getInspectionQuestions(questionPayload, 'Questions');
          this.setState({ datasource, fileUploadList: { ...fileUploadList, [rowIndex]: [] } });
        } else if (InspectionResponse && InspectionResponse.Error) {
          message.error(InspectionResponse.Error);
        }

        // message.warn('No Action Configured');
      }
    } else {
      message.error('Please select Answer');
    }
  };
  fileupload = async (values, key, fileDataObject, rowIndex) => {
    let { datasource, fileUploadList } = this.state;
    if (datasource[rowIndex][key]) {
      datasource[rowIndex][key].push(...values);
    } else {
      datasource[rowIndex][key] = values;
    }
    datasource[rowIndex][key] =
      datasource[rowIndex][key] &&
      datasource[rowIndex][key].reduce((acc, current) => {
        const x = acc.find((item) => item.Name === current.Name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
    datasource[rowIndex] = {
      ...datasource[rowIndex],
      [key]: datasource[rowIndex][key],
      fileDataObject
    };

    fileUploadList = {
      ...fileUploadList,
      [rowIndex]: [
        ...(fileUploadList && fileUploadList[rowIndex] ? fileUploadList[rowIndex] : []),
        fileDataObject
      ]
    };

    this.setState({ datasource, fileUploadList });
  };

  onCheckboxChange = (Answer, rowIndex, Option) => {
    const { datasource } = this.state;
    datasource[rowIndex] = {
      ...datasource[rowIndex],
      Answer: Answer.target.checked === true ? Option : null,
      payload: {
        ...datasource[rowIndex].payload,
        QuestionId: datasource[rowIndex].Id,
        Answer: Answer.target.checked,
        Option
      }
    };
    this.setState({
      datasource
    });
  };

  translation = (keyword) => {
    let { translationCache } = this.props;
    return translationCache && translationCache[keyword] ? translationCache[keyword] : keyword;
  };

  setRemarks = (Remarks, rowIndex) => {
    let { datasource } = this.state;
    datasource[rowIndex] = {
      ...datasource[rowIndex],
      Remarks,
      payload: {
        ...datasource[rowIndex].payload,
        Remarks
      }
    };
    this.setState({ datasource });
  };

  renderTreeNodes = (data) => {
    return (
      data &&
      Array.isArray(data) &&
      data.map((item) => {
        if (item.children && item.children.length > 0) {
          return (
            <Tree.TreeNode
              title={
                item && !item.ParentId
                  ? `${this.translation(item.DisplayCode)}(${this.translation(item.Name)})`
                  : this.translation(item.Name)
              }
              key={item.key}
              dataRef={item}
            >
              {this.renderTreeNodes(item.children, item)}
            </Tree.TreeNode>
          );
        }
        return (
          <Tree.TreeNode
            key={item.key}
            title={<div style={{ color: 'white' }}>{this.translation(item.Name)}</div>}
            dataRef={item}
          />
        );
      })
    );
  };

  onSelect = async (selectedKeys, info) => {
    let { InspectionId } = this.state;
    let status =
      info &&
      info['selectedNodes'] &&
      info['selectedNodes'][0] &&
      info['selectedNodes'][0]['dataRef'] &&
      info['selectedNodes'][0]['dataRef']['Type'] &&
      info['selectedNodes'][0]['dataRef']['Type'] === 'Items';
    if (status) {
      let dataRef =
        info &&
        info['selectedNodes'] &&
        info['selectedNodes'][0] &&
        info['selectedNodes'][0]['dataRef']
          ? info['selectedNodes'][0]['dataRef']
          : {};

      let questionPayload = {
        DeviceAlias: dataRef && dataRef['DeviceAlias'] ? dataRef['DeviceAlias'] : '',
        AreaId: dataRef && dataRef['ParentId'] ? dataRef['ParentId'] : '',
        ItemId: dataRef && dataRef['Code'] ? dataRef['Code'] : '',
        currentDate: moment().utc().format('YYYY-MM-DD'),
        Id: InspectionId ? InspectionId : ''
      };
      let datasource = await getInspectionQuestions(questionPayload, 'Questions');
      let formObject1 = {
        ...questionPayload,
        Description: dataRef.EquipmentName
      };
      this.setState({
        visible: true,
        datasource,
        EquipmentId: dataRef && dataRef['MainParentId'] ? dataRef['MainParentId'] : '',
        formObject: formObject1,
        dataRef
      });
    } else if (status === false) {
      message.error('Please Select Item');
    }
  };
  onExpand = (expandedKeys) => {
    this.setState({
      expandedKeys
    });
  };
  expandAll = async (treeData) => {
    let expandedKeys = await this.getAllKeys(treeData);
    this.setState({
      expandedKeys
    });
  };
  getAllKeys = (data) => {
    // This function makes an array of keys, this is specific for this example, you would have to adopt for your case. If your list is dynamic, also make sure that you call this function everytime data changes.
    const nestedKeys = data.map((node) => {
      let childKeys = [];
      if (node.children) {
        childKeys = this.getAllKeys(node.children);
      }
      return [childKeys, node.key];
    });
    return flattenDeep(nestedKeys);
  };
  handleChange = async (value, key) => {
    let formObject = this.formHeader.current && this.formHeader.current.getFieldsValue();

    formObject = {
      ...formObject,
      [key]: value
    };

    this.formHeader.current.setFieldsValue(formObject);
    this.setState({ [key]: value, formObject });
  };
  onFormsubmit1 = async (values) => {
    let { formObject, fileData, InspectionId } = this.state;
    const siteId = localStorage.get('currentSite');
    let userId =
      this.props.userProfile && this.props.userProfile.user && this.props.userProfile.user.Id
        ? this.props.userProfile.user.Id
        : '';
    let payload = {
      ...formObject,
      ...values,
      ...(InspectionId ? { Id: InspectionId } : ''),
      ...(formObject && formObject.Id ? { UpdatedBy: userId } : {}),
      Ciq_InspectionId: values.Id,
      SiteId: siteId
    };
    let statusResponse = await submitMaster(urlList.insert, payload, fileData, type);
    if (statusResponse) {
      message.success(statusResponse.message);
    } else if (statusResponse && statusResponse.Error) {
      message.error(statusResponse.Error);
    }
  };
  render() {
    let {
      datasource,
      treeData,
      expandedKeys,
      formObject,
      translationCache,
      usersList,
      statusList
    } = this.state;
    let columns = [
      {
        title: this.translation('Question'),
        dataIndex: 'Question',
        key: 'Question'
      },
      {
        title: this.translation('Options'),
        dataIndex: 'Answer',
        key: 'Answer',
        render: (_, data, rowIndex) => (
          <Space size="middle">
            {data &&
              data.Answers.map((item) => {
                return (
                  <>
                    <StyledCheckbox
                      className="check"
                      key={item.Option}
                      value={datasource[rowIndex].Answer}
                      disabled={
                        data.InspectionLogBook && data.InspectionLogBook.length !== 0 ? true : false
                      }
                      checked={
                        data.InspectionLogBook &&
                        data.InspectionLogBook[0] &&
                        data.InspectionLogBook[0].Answer === 'Yes' &&
                        item.Option === 'Yes'
                          ? true
                          : datasource[rowIndex].Answer === 'Yes' && item.Option === 'Yes'
                          ? true
                          : datasource[rowIndex].Answer === 'No' && item.Option === 'No'
                          ? true
                          : data.InspectionLogBook &&
                            data.InspectionLogBook[0] &&
                            data.InspectionLogBook[0].Answer === 'No' &&
                            item.Option === 'No'
                          ? true
                          : false
                      }
                      onChange={(e) => this.onCheckboxChange(e, rowIndex, item.Option, item)}
                      style={{ color: 'rgb(217,142,4)' }}
                    >
                      {this.translation(item.Option)}
                    </StyledCheckbox>
                    <br />
                  </>
                );
              })}
          </Space>
        )
      },
      {
        title: this.translation('Attachment'),
        dataIndex: 'Attachment',
        key: 'Attachment',
        render: (_, record, rowIndex) => {
          let { InspectionLogBook } = record;
          return (
            <FileBase64
              style={{ width: '70%' }}
              Attachments={
                record.InspectionLogBook && record.InspectionLogBook.length !== 0
                  ? InspectionLogBook &&
                    Array.isArray(InspectionLogBook) &&
                    InspectionLogBook[0] &&
                    InspectionLogBook[0].Attachments
                    ? InspectionLogBook[0].Attachments
                    : []
                  : datasource[rowIndex].Attachments
              }
              disableAdd={
                record.InspectionLogBook && record.InspectionLogBook.length !== 0 ? true : false
              }
              fileUpload={(values, key, fileDataObject) =>
                this.fileupload(values, key, fileDataObject, rowIndex)
              }
            ></FileBase64>
          );
        }
      },

      {
        title: this.translation('Remarks'),
        dataIndex: 'Remarks',
        key: 'Remarks',
        render: (_, record, rowIndex) => {
          let { InspectionLogBook } = record;
          if (
            record &&
            record.Answers &&
            datasource &&
            datasource[rowIndex] &&
            datasource[rowIndex].Answer
          ) {
            let list = record.Answers.map((param, index) => {
              if (
                param.Option === datasource[rowIndex].Answer &&
                param.Actions &&
                Array.isArray(param.Actions) &&
                param.Actions.length > 0
              ) {
                return (
                  <TextAreaComponent
                    key={index}
                    rows={3}
                    disabled={
                      record.InspectionLogBook && record.InspectionLogBook.length !== 0
                        ? true
                        : false
                    }
                    defaultValue={
                      record.InspectionLogBook &&
                      record.InspectionLogBook[0] &&
                      record.InspectionLogBook.length !== 0
                        ? record.InspectionLogBook &&
                          record.InspectionLogBook[0] &&
                          record.InspectionLogBook[0].Remarks
                        : ''
                    }
                    style={{ width: '100%' }}
                    placeholder={this.translation('Please input Remarks')}
                    onChange={(e) => this.setRemarks(e.target.value, rowIndex)}
                  />
                );
              } else {
                return InspectionLogBook &&
                  Array.isArray(InspectionLogBook) &&
                  InspectionLogBook[0] &&
                  InspectionLogBook[0].Remarks
                  ? InspectionLogBook[0].Remarks
                  : '';
              }
            });
            return list;
          } else {
            return InspectionLogBook &&
              Array.isArray(InspectionLogBook) &&
              InspectionLogBook[0] &&
              InspectionLogBook[0].Remarks
              ? InspectionLogBook[0].Remarks
              : '';
          }
        }
      },
      {
        title: this.translation('Action'),
        key: 'action',
        render: (_, data, rowIndex) => (
          <Space size="middle">
            <StyledButton
              theme={themeSettings}
              onClick={() => {
                this.onFormsubmit(rowIndex);
              }}
              disabled={
                data.InspectionLogBook &&
                data.InspectionLogBook[0] &&
                data.InspectionLogBook.length !== 0
                  ? true
                  : false
              }
            >
              {this.translation(constant.submit)}
            </StyledButton>
          </Space>
        )
      }
    ];
    return (
      <>
        <StyledComponent
          theme={themeSettings}
          style={{ padding: '15px', minHeight: window.innerHeight - 143 }}
        >
          <StyledForm
            name="SR"
            theme={themeSettings}
            ref={this.formHeader}
            onFinish={(data) => this.onFormsubmit1(data)}
            initialValues={formObject}
            layout="horizontal"
            labelCol={{ flex: '130px' }}
            labelAlign="right"
            labelWrap
            wrapperCol={{ flex: 1 }}
          >
            <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
              <StyledButton style={{ marginLeft: '5px' }} theme={themeSettings} htmlType="submit">
                {translation(translationCache, constant.submit)}
              </StyledButton>
            </div>
            <StyledBox>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    marginTop: '50px'
                  }}
                  label={translation(translationCache, constant.InspectionId)}
                  name="InspectionId"
                  rules={[{ required: true, message: 'Input InspectionId!' }]}
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'InspectionId')}
                    value={formObject.InspectionId}
                    disabled
                    onChange={(e) => this.handleChange(e.target.value, 'InspectionId')}
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    width: '100%',
                    flex: '50%',
                    marginTop: '50px'
                  }}
                  label={translation(translationCache, constant.Description)}
                  name="Description"
                  rules={[{ required: true, message: 'Input Description!' }]}
                >
                  <InputComponent
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'Description')}
                    value={formObject.Description}
                    disabled
                    onChange={(e) => this.handleChange(e.target.value, 'Description')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.createdBy)}
                  name="CreatedBy"
                  rules={[{ required: false, message: 'Select Created By' }]}
                >
                  <Select
                    style={{ width: '70%' }}
                    disabled={true}
                    placeholder={translation(translationCache, 'Created By')}
                    onChange={(value) => this.handleChange(value, 'CreatedBy')}
                  >
                    {usersList &&
                      Array.isArray(usersList) &&
                      usersList &&
                      usersList.map((users, index) => {
                        return (
                          <Option key={index} value={users.Id}>
                            {' '}
                            {users.FirstName}{' '}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>

                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.CreatedDate)}
                  name="InspectionCreationTime"
                  rules={[{ required: false, message: 'Created Date' }]}
                >
                  <DatePicker
                    format={'YYYY-MM-DD HH:mm:ss'}
                    style={{ width: '70%' }}
                    disabled={true}
                    placeholder={translation(translationCache, 'Created Date')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.actualFinish)}
                  name="ActualEndDate"
                  rules={[{ required: false, message: 'Actual Finish' }]}
                >
                  <DatePicker
                    style={{ width: '70%' }}
                    placeholder={translation(translationCache, 'ActualFinish')}
                  />
                </Form.Item>
                <Form.Item
                  style={{ width: '100%', flex: '50%' }}
                  label={translation(translationCache, constant.status)}
                  name="StatusId"
                  rules={[{ required: true, message: 'Please Select Status' }]}
                >
                  <Select
                    placeholder={translation(translationCache, 'Select Status')}
                    onChange={(value) => this.handleChange(value, 'StatusId')}
                    style={{ width: '70%' }}
                  >
                    {statusList &&
                      Array.isArray(statusList) &&
                      statusList.map((status, index) => {
                        return (
                          <Option key={index} value={status.Value}>
                            {translation(translationCache, status.Key)}
                          </Option>
                        );
                      })}
                  </Select>
                </Form.Item>
              </div>
            </StyledBox>
          </StyledForm>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <div
              style={{
                borderRight: '1px dashed white',
                width: '50%',
                flex: '10%',
                overflowY: 'auto',
                height: window.innerHeight - 250
              }}
            >
              <StyledTree
                className="treeTheme"
                onExpand={this.onExpand}
                expandedKeys={expandedKeys}
                style={{ width: '100%', flex: '50%', padding: '20px' }}
                onSelect={this.onSelect}
                showLine
                defaultExpandAll={true}
                switcherIcon={<DownOutlined />}
              >
                {this.renderTreeNodes(treeData)}
              </StyledTree>
            </div>
            <div
              style={{
                width: '100%',
                flex: '50%',
                padding: '10px',
                borderLeft: '3px dashed steelblue',
                minHeight: window.innerHeight - 143
              }}
            >
              <StyledTable theme={themeSettings} columns={columns} dataSource={datasource} />
            </div>
          </div>
        </StyledComponent>
      </>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  translationCache: getTranslationCache()
});
export default connect(mapStateToProps)(InspectionForm);
